import { onMount, createSignal, For, Show, createMemo } from 'solid-js';
import IconLoaderAnimation from '~/assets/icons/loderAnimation.svg';
import {
  PRICE,
  MIN,
  MAX,
  NO_MIN,
  NO_MAX,
  HOME_TYPE,
  RESET,
  SLIDE_UP_TO_LOAD_MORE,
  MOVE_IN_DATE,
  SEARCH_FOR_YOUR_DREAM_HOME,
  FILTERS,
  FILTER,
  APPLY,
} from '~/assets/strings';
import { Button } from '~/components/common/buttons';
import { PropertyItem } from '~/components/property-item/PropertyItem';
import { PropertyItemSkeleton } from '~/components/property-item/PropertyItemSkeleton';
import { IconSearch, IconX } from '~/components/ui';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useLocalization } from '~/hooks/useLocalization';
import { NothingHere } from '~/pages/properties/NothingHere';
import { CompanyUnitListingPresenter } from '~/presenters/CompanyUnitListingPresenter';
import { CompanyUnitSearchParamsPresenter } from '~/presenters/CompanyUnitSearchParamsPresenter';
import { GetCompanyUnitsListingUseCase } from '~/use-cases/company-unit-listing/getCompanyListingUseCase';
import { UpdateListingSearchParamsUseCase } from '~/use-cases/company-unit-listing/updateListingSearchParamsUseCase';
import { debounce } from '~/utils/tools';
import { SearchBedBaths } from './SearchBedBaths';
import { SearchCheckbox } from './SearchCheckbox';
import { SearchMoveInDate } from './SearchMoveindate';
import { SearchRange } from './SearchRange';

const { t } = useLocalization();

export const PropertiesSearchList = () => {
  const [page, setPage] = createSignal(1);
  const [pageSize] = createSignal(9);
  const [showMobileFilters, setShowMobileFilters] = createSignal(false);
  const { execute: updateParams, isLoading: isUpdateParamsLoading } = useUseCase(UpdateListingSearchParamsUseCase);
  const { model: searchParamsConfig } = usePresenter(CompanyUnitSearchParamsPresenter);
  const { model: unitListing } = usePresenter(CompanyUnitListingPresenter);
  const [loaderRef, setLoaderRef] = createSignal<HTMLDivElement | null>(null);

  const { execute: getUnits, isLoading: isLoadingUnits } = useUseCase(GetCompanyUnitsListingUseCase);

  const priceConfig = createMemo(() => {
    return {
      start: {
        title: t(MIN),
        placeholder: t(NO_MIN),
        options: searchParamsConfig()?.minPriceOptions ?? [],
      },
      end: {
        title: t(MAX),
        placeholder: t(NO_MAX),
        options: searchParamsConfig()?.maxPriceOptions ?? [],
      },
    };
  });

  const scrollToTop = () => {
    document.documentElement.scrollTo({
      top: 0,
    });
  };

  const handleChangeParams = async (key: string, value: any, isLoadImmediately = true) => {
    const params: any = {
      ...searchParamsConfig()?.searchParams,
    };

    switch (key) {
      case 'bads&baths':
        params.minBaths = value.minBaths;
        params.minBeds = value.minBeds;
        break;
      case 'price':
        params.minRentalPrice = value.start;
        params.maxRentalPrice = value.end;
        break;
      default:
        params[key] = value;
        break;
    }
    await updateParams(params);

    isLoadImmediately && handleGetUnits();
  };

  const handleGetUnits = (isFirstLoad = false) => {
    setPage(1);
    getUnits({
      ...searchParamsConfig()?.searchParams,
      page: 1,
      pageSize: pageSize(),
    });
    if (!isFirstLoad) {
      scrollToTop();
      setShowMobileFilters(false);
    }
  };

  onMount(() => {
    handleGetUnits(true);
  });

  const reset = async () => {
    await updateParams({});
    handleGetUnits();
  };

  const loadMore = () => {
    if ((unitListing()?.totalPages ?? 1) <= page()) return;
    setPage(page() + 1);
    getUnits({
      ...searchParamsConfig()?.searchParams,
      page: page(),
      pageSize: pageSize(),
    });
  };

  onMount(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isUpdateParamsLoading()) {
          loadMore();
        }
      },
      { threshold: 0.1 }
    );

    const currentLoaderRef = loaderRef();
    if (currentLoaderRef) {
      observer.observe(currentLoaderRef);
    }

    return () => {
      if (currentLoaderRef) {
        observer.unobserve(currentLoaderRef);
      }
    };
  });

  const onSearch = debounce((value: string) => {
    handleChangeParams('search', value || undefined);
  }, 500);

  return (
    <>
      <div class="fixed top-[70px] z-40 w-full bg-border-level01 md:top-[135px]">
        <div class="mx-auto w-full max-w-container p-4 md:px-6 lg:h-[116px]">
          <div class="hidden h-full lg:flex lg:flex-row lg:items-center lg:gap-4">
            <div class="flex flex-1 flex-row gap-4">
              <div class="flex flex-1 items-center gap-3 rounded-lg border border-[#CBD4EB] px-4 py-[14px] text-[#222735]">
                <IconSearch class="pointer-events-none size-5 cursor-pointer text-[#CBD4EB]" />
                <input
                  id="property-search-input"
                  type="text"
                  class="w-full bg-transparent placeholder:text-auxiliary-text focus:outline-none"
                  onInput={(e) => onSearch(e.target.value)}
                  value={searchParamsConfig()?.searchParams.search || ''}
                  placeholder={t(SEARCH_FOR_YOUR_DREAM_HOME)}
                />
              </div>
              <SearchRange
                id="property-price-range"
                valueDisplay={searchParamsConfig()?.priceDisplay}
                placeholder={t(PRICE)}
                onChange={(value) => handleChangeParams('price', value)}
                value={{
                  start: searchParamsConfig()?.searchParams.minRentalPrice || '',
                  end: searchParamsConfig()?.searchParams.maxRentalPrice || '',
                }}
                title={t(PRICE)}
                start={priceConfig().start}
                end={priceConfig().end}
              />
              <SearchCheckbox
                id="property-type-checkbox"
                valueDisplay={searchParamsConfig()?.propertyTypesDisplay}
                onChange={(value) => handleChangeParams('propertyTypes', value)}
                value={searchParamsConfig()?.searchParams.propertyTypes}
                title={t(HOME_TYPE)}
                options={searchParamsConfig()?.homeTypeOptions || []}
              />
              <SearchMoveInDate
                id="property-move-in-date"
                valueDisplay={
                  searchParamsConfig()?.searchParams.availableFrom
                    ? `${t(MOVE_IN_DATE)} ${searchParamsConfig()?.searchParams.availableFrom}`
                    : ''
                }
                moveInDate={searchParamsConfig()?.searchParams.availableFrom as string}
                onChange={(value) => handleChangeParams('availableFrom', value)}
              />
              <SearchBedBaths
                bedOptions={searchParamsConfig()?.bedOptions}
                bathOptions={searchParamsConfig()?.bathOptions}
                valueDisplay={searchParamsConfig()?.bdBaDisplay}
                baths={searchParamsConfig()?.searchParams.minBaths || 0}
                beds={searchParamsConfig()?.searchParams.minBeds || 0}
                onChange={(value) => handleChangeParams('bads&baths', value)}
              />
            </div>

            <Show when={JSON.stringify(searchParamsConfig()?.searchParams) !== '{}'}>
              <Button id="property-search-reset-btn" class="bg-white text-primary-color" onClick={reset}>
                {t(RESET)}
              </Button>
            </Show>
          </div>

          <div class="flex items-center gap-3 lg:hidden">
            <div class="flex flex-1 items-center gap-3 rounded-lg border border-[#CBD4EB] px-4 py-3">
              <IconSearch class="size-5 text-[#CBD4EB]" />
              <input
                id="property-search-dream-home-input"
                type="text"
                class="w-full bg-transparent text-sm placeholder:text-auxiliary-text focus:outline-none"
                onInput={(e) => onSearch(e.target.value)}
                value={searchParamsConfig()?.searchParams.search || ''}
                placeholder={t(SEARCH_FOR_YOUR_DREAM_HOME)}
              />
            </div>
            <button
              id="property-filter-home-btn"
              onClick={() => setShowMobileFilters(true)}
              class="flex items-center justify-center rounded-lg border border-[#CBD4EB] p-3">
              <div>{t(FILTER)}</div>
            </button>
          </div>
        </div>
      </div>

      <Show when={showMobileFilters()}>
        <div class="fixed inset-0 z-50 bg-black/50 lg:hidden">
          <div class="absolute bottom-0 w-full rounded-t-xl bg-white p-4 pb-16">
            <div class="mb-4 flex items-center justify-between">
              <h3 class="flex-1 text-center text-lg font-semibold">{t(FILTERS)}</h3>
              <button id="property-mobile-ilter-close-btn" onClick={() => setShowMobileFilters(false)} class="p-2">
                <IconX class="size-5" />
              </button>
            </div>

            <div class="flex h-modalMd flex-col gap-4 overflow-auto">
              <SearchRange
                id="property-price-range"
                valueDisplay={searchParamsConfig()?.priceDisplay}
                placeholder={t(PRICE)}
                onChange={(value) => handleChangeParams('price', value, false)}
                value={{
                  start: searchParamsConfig()?.searchParams.minRentalPrice || '',
                  end: searchParamsConfig()?.searchParams.maxRentalPrice || '',
                }}
                title={t(PRICE)}
                start={priceConfig().start}
                end={priceConfig().end}
              />

              <SearchMoveInDate
                id="property-move-in-date"
                valueDisplay={
                  searchParamsConfig()?.searchParams.availableFrom
                    ? `${t(MOVE_IN_DATE)} ${searchParamsConfig()?.searchParams.availableFrom}`
                    : ''
                }
                moveInDate={searchParamsConfig()?.searchParams.availableFrom as string}
                onChange={(value) => handleChangeParams('availableFrom', value, false)}
              />
              <SearchBedBaths
                bedOptions={searchParamsConfig()?.bedOptions}
                bathOptions={searchParamsConfig()?.bathOptions}
                valueDisplay={searchParamsConfig()?.bdBaDisplay}
                baths={searchParamsConfig()?.searchParams.minBaths || 0}
                beds={searchParamsConfig()?.searchParams.minBeds || 0}
                onChange={(value) => handleChangeParams('bads&baths', value, false)}
              />
              <SearchCheckbox
                id="property-type-checkbox"
                valueDisplay={searchParamsConfig()?.propertyTypesDisplay}
                onChange={(value) => handleChangeParams('propertyTypes', value, false)}
                value={searchParamsConfig()?.searchParams.propertyTypes}
                title={t(HOME_TYPE)}
                options={searchParamsConfig()?.homeTypeOptions || []}
              />
            </div>

            <div class="mt-4 flex gap-3">
              <Button id="property-search-reset-btn" class="flex-1 justify-center bg-white text-primary-color" onClick={reset}>
                {t(RESET)}
              </Button>
              <div
                id="property-search-apply-btn"
                onClick={() => handleGetUnits()}
                class="flex h-[44px] w-full cursor-pointer items-center justify-center rounded-lg bg-primary-color text-white">
                {t(APPLY)}
              </div>
            </div>
          </div>
        </div>
      </Show>

      <div class="mx-auto mt-[120px] w-full max-w-container px-4 md:mt-[150px] md:px-6">
        <div class="mt-[46px] w-full">
          <div class="mb-[400px] mt-[57px]">
            <Show when={!unitListing()?.allItems.length && !isLoadingUnits()}>
              <NothingHere />
            </Show>

            <div class="grid w-full grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 md:gap-6">
              <For each={unitListing()?.allItems}>{(item) => <PropertyItem data={item} />}</For>
              <Show when={isLoadingUnits()}>
                <For each={Array(6)}>{() => <PropertyItemSkeleton />}</For>
              </Show>
            </div>

            <div class="mt-8 flex w-full flex-col items-center justify-center md:mt-[56px]" ref={setLoaderRef}>
              <Show when={(unitListing()?.totalPages ?? 1) > page() && unitListing()?.items.length}>
                <img src={IconLoaderAnimation} class="w-[150px] sm:w-[200px]" />
                <div class="text-sm sm:text-base">{t(SLIDE_UP_TO_LOAD_MORE)}</div>
              </Show>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
